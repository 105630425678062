<template>
  <v-container fluid class="passenger-reviews-page">
    <v-row>
      <v-col cols="12" md="12" lg="12" xl="12">
        <div :id="window.width < 600 ? 'passenger-reviews-page-step-0' : ''"></div>
        <v-data-table
          :footer-props="{
            pageText: `{0}-{1} ${$t('tables.of')} {2}`,
            itemsPerPageText: $t('tables.reviewsByPage'),
            itemsPerPageOptions: [5, 10, 20, 50, 100],
          }"
          :headers="passengerReviewsHeaders"
          :disable-sort="tourActive"
          :class="tourActive ? 'no-pointers' : ''"
          :no-data-text="$t('tables.noDataText')"
          :no-results-text="$t('tables.noDataText')"
          :items="passengerReviews"
          :search="searchString"
          class="elevation-1 cursor-pointer"
          :items-per-page="10"
          @click:row="openTransfer"
          multi-sort>
          <template v-slot:top>
            <v-toolbar flat>
              <template>
                <v-toolbar-title>
                  {{ $t('tables.passengerReviews') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  id="passenger-reviews-page-step-1"
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <p :id="window.width > 599 ? 'passenger-reviews-page-step-0' : ''" class="first-step"></p>

                <v-spacer />
              </template>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <div class="tourStyle">
      <v-tour name="passengerReviewsTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import router from '@/router';
import { passengerReviewsHeaders } from '@/mixins/data-table-headers';
import { formatDateTime } from '@/utils/formatDate';
import { getAllTourSteps, passengerReviewsPageSteps } from '@/mixins/onboarding-tutorial-steps';
import { mapState } from 'vuex';

export default {
  name: 'DashboardPage',
  props: [],
  components: {},
  data() {
    return {
      passengerReviews: [],
      searchString: '',
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      steps: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
    };
  },
  created() {
    this.getDashboardData();

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    passengerReviewsHeaders() {
      return passengerReviewsHeaders(i18n);
    },
  },
  methods: {
    async getDashboardData() {
      await this.$store.dispatch('passengerReviews/getAllReviews').then((res) => {
        this.passengerReviews = res.data.map((item) => {
          item.created_at = formatDateTime(item.created_at);

          return item;
        });
      });

      this.setStepsAndStartTour();
    },
    openTransfer(item) {
      // Resolve the URL from the router
      const routeData = router.resolve({
        name: this.$store.getters['auth/role'] == 1 ? 'TabularView' : 'CalendarView',
        query: { driving_id: item.driving.id },
      });

      // Open a new tab with the resolved URL
      window.open(routeData.href, '_blank');
    },

    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },

    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '8')
            ?.map((item) => item.step_id);
          this.steps = passengerReviewsPageSteps();
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }

        resolve();
      });
    },

    startTour() {
      this.$tours['passengerReviewsTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
    },

    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },

    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);

      const body = {
        objects: this.steps.map((item) => ({
          component_id: '8',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },

    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '8',
        step_id: `#passenger-reviews-page-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '8',
            step_id: `#passenger-reviews-page-step-${step}`,
          },
        ],
      };
      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
  beforeDestroy() {
    document.body.classList.remove('v-tour--active');
    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },
  watch: {
    tourActive() {
      this.setStepsAndStartTour();
    },
    '$store.state.onboardingTutorial.tourReset': {
      handler() {
        if (this.$store.state.onboardingTutorial.tourReset) {
          this.setStepsAndStartTour(); // Function to start your Vue Tour

          // Clear the tour reset flag to avoid repeat triggers
          this.$store.dispatch('onboardingTutorial/clearTourReset');
        }
      },
    },
  },
};
</script>

<style>
.passenger-reviews-page {
  .no-pointers {
    pointer-events: none;
  }

  .v-step {
    inset: 0 0 auto -10px !important;
  }

  .first-step {
    margin-top: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
